/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

const Footers = () => {
    return (
        <>
            {/* FOOTER AREA START */}
            <footer className="ltn__footer-area  ">
                <div className="footer-top-area  section-bg-2 plr--5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                                <div className="footer-widget footer-about-widget">
                                    <div className="footer-logo">
                                        <div className="site-logo">
                                            <img style={{'height': '69px',  'width': '102px'}} src="logo.svg" alt="Logo" />
                                        </div>
                                    </div>
                                    <p>
                                        Fadian Collection, UMKM dari Semarang, menyediakan produk unggulan seperti masker, majun, madu, dan sarung tangan, dengan dedikasi untuk memberikan manfaat maksimal bagi konsumen melalui sentuhan kreativitas dalam setiap produk yang dirancang.
                                    </p>
                                    <div className="footer-address">
                                        <ul>
                                            <li>
                                                <div className="footer-address-icon">
                                                    <i className="icon-placeholder" />
                                                </div>
                                                <div className="footer-address-info">
                                                    <p>Genuk Semarang Timur</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-address-icon">
                                                    <i className="icon-call" />
                                                </div>
                                                <div className="footer-address-info">
                                                    <p>
                                                        <a href="tel:08122825930">Bp Paimo Mendem</a>
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-address-icon">
                                                    <i className="icon-mail" />
                                                </div>
                                                <div className="footer-address-info">
                                                    <p>
                                                        <a href="mailto:paimomendem@gmail.com">
                                                            paimomendem@gmail.com
                                                        </a>
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="ltn__social-media mt-20">
                                        <ul>
                                            <li>
                                                <a href="#" title="Facebook">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" title="Twitter">
                                                    <i className="fab fa-twitter" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" title="Linkedin">
                                                    <i className="fab fa-linkedin" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" title="Youtube">
                                                    <i className="fab fa-youtube" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                            </div>
                            <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                            </div>
                            <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                            </div>
                            <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ltn__copyright-area ltn__copyright-2 section-bg-2 ltn__border-top-2 plr--5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="ltn__copyright-design clearfix text-center">
                                    <p>
                                        All Rights Reserved @ Fadian Collection <span className="current-year" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* FOOTER AREA END */}
        </>

    )
}

export default Footers;