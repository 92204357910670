import { AssetsCss, AssetsJs } from "./Assets";

const CssLandingPage = () => {
    const { REACT_APP_Url } = process.env;
    AssetsCss(REACT_APP_Url + '/assets/css/font-icons.css');
    AssetsCss(REACT_APP_Url + '/assets/css/plugins.css');
    AssetsCss(REACT_APP_Url + '/assets/css/style.css');
    AssetsCss(REACT_APP_Url + '/assets/css/custom.css');
    AssetsCss(REACT_APP_Url + '/assets/css/responsive.css');
    return (
        <>
        </>
    )
}
const JsLandingPage = () => {
    const { REACT_APP_Url } = process.env;
    AssetsJs(REACT_APP_Url + '/assets/js/plugins.js');
    AssetsJs(REACT_APP_Url + '/assets/js/main.js');
    return (
        <>
        </>
    )
}

export { CssLandingPage, JsLandingPage };

