import { useEffect } from 'react';

const AssetsJs = (url) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = false;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};
const AssetsCss = (url) => {
  useEffect(() => {
    const link = document.createElement('link');

    link.href = url;
    link.rel = 'stylesheet';
    // link.async = false;

    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    }
  }, [url]);
};

export { AssetsCss, AssetsJs };