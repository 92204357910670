import {BrowserRouter, Routes, Route} from "react-router-dom"
import {Main} from "./pages"
import AboutUs from "./components/AboutUs";
function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route index path="/" element={<Main />} />
                    <Route index path="/about-us" element={<AboutUs />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App;
