import React from "react";
import { Header, AssetsCss, JumbotronSlider, Banner, Feature, OurProduct, Footers } from "../components";

const Main = () => {
    return (
        <>
            <AssetsCss />
            <Header />
            <OurProduct />
            <Footers />
            {/* <JumbotronSlider />
            <Banner />
            <Feature /> */}
        </>

    )
}

export default Main;