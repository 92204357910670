import { createRoot } from "react-dom/client"
import {JsLandingPage, CssLandingPage} from "./components"

import App from "./App"

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);


root.render(
    <>
        <CssLandingPage />
        <App />
        <JsLandingPage />
    </>
)
