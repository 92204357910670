/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import { dataProducts } from '../services'

const OurProduct = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(dataProducts);
    })


    return (
        <>
            {/* PRODUCT TAB AREA START (product-item-3) */}
            <div className="ltn__product-tab-area ltn__product-gutter pt-115 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-area ltn__section-title-2 text-center">
                                <h1 className="section-title">Our Products</h1>
                            </div>
                            <div className="ltn__tab-menu ltn__tab-menu-2 ltn__tab-menu-top-right-- text-uppercase text-center">
                                <div className="nav">
                                    <a
                                        className="active show"
                                        data-toggle="tab"
                                        href="#liton_tab_3_1"
                                    >
                                        All
                                    </a>
                                    <a data-toggle="tab" href="#liton_tab_3_2" className="">
                                        Majun
                                    </a>
                                    <a data-toggle="tab" href="#liton_tab_3_3" className="">
                                        Kaos
                                    </a>
                                    <a data-toggle="tab" href="#liton_tab_3_4" className="">
                                        Masker
                                    </a>
                                    <a data-toggle="tab" href="#liton_tab_3_5" className="">
                                        Bendera
                                    </a>
                                    <a data-toggle="tab" href="#liton_tab_3_6" className="">
                                        Sarung Tangan
                                    </a>
                                    <a data-toggle="tab" href="#liton_tab_3_7" className="">
                                        Others
                                    </a>
                                </div>
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane fade active show" id="liton_tab_3_1">
                                    <div className="ltn__product-tab-content-inner">
                                        <div className="row ltn__tab-product-slider-one-active slick-arrow-1">
                                            {/* ltn__product-item */}
                                            
                                                {data.map((data, index) => {
                                                    return (
                                                        <div className="col-lg-12" key={'all_' + Math.random()}>
                                                            <div className="ltn__product-item ltn__product-item-3 text-center" >
                                                                <div className="product-img">
                                                                    <a href="#">
                                                                        <img src={data.image} alt="#" className="img-custom-static" />
                                                                    </a>
                                                                </div>
                                                                <div className="product-info">
                                                                    <div className="product-ratting">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star-half-alt" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="far fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li className="review-total">
                                                                                {" "}
                                                                                <a href="#"> {data.rating}</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <h2 className="product-title">
                                                                        <a href="#">{data.name}</a>
                                                                    </h2>
                                                                    <div className="product-price">
                                                                        {/* <span>$32.00</span>
                                                                        <del>$46.00</del> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                })}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="liton_tab_3_2">
                                    <div className="ltn__product-tab-content-inner">
                                        <div className="row ltn__tab-product-slider-one-active slick-arrow-1">
                                            {/* ltn__product-item */}
                                            {data.map((data, index) => {
                                                if (data.type === 1) {
                                                    return (
                                                        <div className="col-lg-12"  key={'all1_' + Math.random()}>
                                                            <div className="ltn__product-item ltn__product-item-3 text-center">
                                                                <div className="product-img">
                                                                    <a href="#">
                                                                        <img src={data.image} alt="#" className="img-custom-static" />
                                                                    </a>
                                                                </div>
                                                                <div className="product-info">
                                                                    <div className="product-ratting">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star-half-alt" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="far fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li className="review-total">
                                                                                {" "}
                                                                                <a href="#"> {data.rating}</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <h2 className="product-title">
                                                                        <a href="#">{data.name}</a>
                                                                    </h2>
                                                                    <div className="product-price">
                                                                        {/* <span>$32.00</span>
                                                                        <del>$46.00</del> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="liton_tab_3_3">
                                    <div className="ltn__product-tab-content-inner">
                                        <div className="row ltn__tab-product-slider-one-active slick-arrow-1">
                                            {/* ltn__product-item */}
                                            {data.map((data, index) => {
                                                if (data.type === 2) {
                                                    return (
                                                        <div className="col-lg-12"  key={'all_2' + Math.random()}>
                                                            <div className="ltn__product-item ltn__product-item-3 text-center">
                                                                <div className="product-img">
                                                                    <a href="#">
                                                                        <img src={data.image} alt="#" className="img-custom-static" />
                                                                    </a>
                                                                </div>
                                                                <div className="product-info">
                                                                    <div className="product-ratting">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star-half-alt" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="far fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li className="review-total">
                                                                                {" "}
                                                                                <a href="#"> {data.rating}</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <h2 className="product-title">
                                                                        <a href="#">{data.name}</a>
                                                                    </h2>
                                                                    <div className="product-price">
                                                                        {/* <span>$32.00</span>
                                                                        <del>$46.00</del> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="liton_tab_3_4">
                                    <div className="ltn__product-tab-content-inner">
                                        <div className="row ltn__tab-product-slider-one-active slick-arrow-1">
                                            {/* ltn__product-item */}
                                            {data.map((data, index) => {
                                                if (data.type === 3) {
                                                    return (
                                                        <div className="col-lg-12"  key={'all_3' + Math.random()}>
                                                            <div className="ltn__product-item ltn__product-item-3 text-center">
                                                                <div className="product-img">
                                                                    <a href="#">
                                                                        <img src={data.image} alt="#" className="img-custom-static" />
                                                                    </a>
                                                                </div>
                                                                <div className="product-info">
                                                                    <div className="product-ratting">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star-half-alt" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="far fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li className="review-total">
                                                                                {" "}
                                                                                <a href="#"> {data.rating}</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <h2 className="product-title">
                                                                        <a href="#">{data.name}</a>
                                                                    </h2>
                                                                    <div className="product-price">
                                                                        {/* <span>$32.00</span>
                                                                        <del>$46.00</del> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="liton_tab_3_5">
                                    <div className="ltn__product-tab-content-inner">
                                        <div className="row ltn__tab-product-slider-one-active slick-arrow-1">
                                            {/* ltn__product-item */}
                                            {data.map((data, index) => {
                                                if (data.type === 4) {
                                                    return (
                                                        <div className="col-lg-12"  key={'all_4' + Math.random()}>
                                                            <div className="ltn__product-item ltn__product-item-3 text-center">
                                                                <div className="product-img">
                                                                    <a href="#">
                                                                        <img src={data.image} alt="#" className="img-custom-static" />
                                                                    </a>
                                                                </div>
                                                                <div className="product-info">
                                                                    <div className="product-ratting">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star-half-alt" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="far fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li className="review-total">
                                                                                {" "}
                                                                                <a href="#"> {data.rating}</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <h2 className="product-title">
                                                                        <a href="#">{data.name}</a>
                                                                    </h2>
                                                                    <div className="product-price">
                                                                        {/* <span>$32.00</span>
                                                                        <del>$46.00</del> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="liton_tab_3_6">
                                    <div className="ltn__product-tab-content-inner">
                                        <div className="row ltn__tab-product-slider-one-active slick-arrow-1">
                                            {/* ltn__product-item */}
                                            {data.map((data, index) => {
                                                if (data.type === 5) {
                                                    return (
                                                        <div className="col-lg-12" key={'all_5' + Math.random()}>
                                                            <div className="ltn__product-item ltn__product-item-3 text-center">
                                                                <div className="product-img">
                                                                    <a href="#">
                                                                        <img src={data.image} alt="#" className="img-custom-static" />
                                                                    </a>
                                                                </div>
                                                                <div className="product-info">
                                                                    <div className="product-ratting">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star-half-alt" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="far fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li className="review-total">
                                                                                {" "}
                                                                                <a href="#"> {data.rating}</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <h2 className="product-title">
                                                                        <a href="#">{data.name}</a>
                                                                    </h2>
                                                                    <div className="product-price">
                                                                        {/* <span>$32.00</span>
                                                                        <del>$46.00</del> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="liton_tab_3_7">
                                    <div className="ltn__product-tab-content-inner">
                                        <div className="row ltn__tab-product-slider-one-active slick-arrow-1">
                                            {/* ltn__product-item */}
                                            {data.map((data, index) => {
                                                if (data.type === 6) {
                                                    return (
                                                        <div className="col-lg-12" key={'all_6' + Math.random()}>
                                                            <div className="ltn__product-item ltn__product-item-3 text-center">
                                                                <div className="product-img">
                                                                    <a href="#">
                                                                        <img src={data.image} alt="#" className="img-custom-static" />
                                                                    </a>
                                                                </div>
                                                                <div className="product-info">
                                                                    <div className="product-ratting">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="fas fa-star-half-alt" />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <i className="far fa-star" />
                                                                                </a>
                                                                            </li>
                                                                            <li className="review-total">
                                                                                {" "}
                                                                                <a href="#"> {data.rating}</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <h2 className="product-title">
                                                                        <a href="#">{data.name}</a>
                                                                    </h2>
                                                                    <div className="product-price">
                                                                        {/* <span>$32.00</span>
                                                                        <del>$46.00</del> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* PRODUCT TAB AREA END */}
        </>

    )
}

export default OurProduct;