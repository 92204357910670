/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

const Header = () => {
    return (
        <>
            {/* HEADER AREA START (header-5) */}
            <header className="ltn__header-area ltn__header-5 ltn__header-transparent-- gradient-color-4---">
                {/* ltn__header-top-area start */}
                <div className="ltn__header-top-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="ltn__top-bar-menu">
                                    <ul>
                                        <li>
                                            <a target="blank" href="https://www.google.com/maps/place/Jual+masker+kain+dan+majun+dan+Sarung+tangan+Buldozer+dan+karet+elastic+baby+(MURAH)/@-6.9587667,110.4808381,17z/data=!4m5!3m4!1s0x0:0xc02e8fa614e4401b!8m2!3d-6.9587561!4d110.4830129">
                                                <i className="icon-placeholder" /> Semarang
                                            </a>
                                        </li>
                                        <li>
                                            <a href="mailto:paimomendem@gmail.com?Subject=Flower%20greetings%20to%20you">
                                                <i className="icon-mail" /> paimomendem@gmail.com
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="top-bar-right text-right">
                                    <div className="ltn__top-bar-menu">
                                        <ul>
                                            <li>
                                                {/* ltn__language-menu */}
                                                {/* <div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
                                                    <ul>
                                                        <li>
                                                            <a href="#" className="dropdown-toggle">
                                                                <span className="active-currency">English</span>
                                                            </a>
                                                            <ul>
                                                                <li>
                                                                    <a href="#">Arabic</a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">Bengali</a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">Chinese</a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">English</a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">French</a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">Hindi</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                            </li>
                                            <li>
                                                {/* ltn__social-media */}
                                                <div className="ltn__social-media">
                                                    <ul>
                                                        <li>
                                                            <a href="#" title="Facebook">
                                                                <i className="fab fa-facebook-f" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" title="Twitter">
                                                                <i className="fab fa-twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" title="Instagram">
                                                                <i className="fab fa-instagram" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" title="Dribbble">
                                                                <i className="fab fa-dribbble" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ltn__header-top-area end */}
                {/* ltn__header-middle-area start */}
                <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white sticky-active-into-mobile plr--9---">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="site-logo-wrap">
                                    <div className="site-logo">
                                        <a href="#">
                                            <img style={{'height': '69px',  'width': '102px'}} src="logo.svg" alt="Logo" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col header-menu-column menu-color-white---">
                                <div className="header-menu d-none d-xl-block">
                                    <nav>
                                        <div className="ltn__main-menu" style={{float: 'right'}}>
                                            <ul>
                                                <li>
                                                    <a href="#">Home</a>
                                                </li>
                                                <li>
                                                    <a href="/about-us">About</a>
                                                </li>
                                                <li>
                                                    <a href="#">Contact</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div className="ltn__header-options ltn__header-options-2 mb-sm-20">
                                {/* header-search-1 */}
                                <div className="header-search-wrap">
                                    <div className="header-search-1">
                                        <div className="search-icon">
                                            <i className="icon-search for-search-show" />
                                            <i className="icon-cancel  for-search-close" />
                                        </div>
                                    </div>
                                    <div className="header-search-1-form">
                                        <form id="#" method="get" action="#">
                                            <input
                                                type="text"
                                                name="search"
                                                defaultValue=""
                                                placeholder="Search here..."
                                            />
                                            <button type="submit">
                                                <span>
                                                    <i className="icon-search" />
                                                </span>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                                {/* mini-cart */}
                                {/* Mobile Menu Button */}
                                <div className="mobile-menu-toggle d-xl-none">
                                    <a
                                        href="#ltn__utilize-mobile-menu"
                                        className="ltn__utilize-toggle"
                                    >
                                        <svg viewBox="0 0 800 600">
                                            <path
                                                d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                                                id="top"
                                            />
                                            <path d="M300,320 L540,320" id="middle" />
                                            <path
                                                d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                                                id="bottom"
                                                transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ltn__header-middle-area end */}
            </header>
            {/* HEADER AREA END */}
            {/* Utilize Mobile Menu Start */}
            <div
                id="ltn__utilize-mobile-menu"
                className="ltn__utilize ltn__utilize-mobile-menu"
            >
                <div className="ltn__utilize-menu-inner ltn__scrollbar">
                    <div className="ltn__utilize-menu-head">
                        <div className="site-logo">
                            <a href="#">
                                <img style={{'height': '69px',  'width': '102px'}} src="logo.svg" />
                            </a>
                        </div>
                        <button className="ltn__utilize-close">×</button>
                    </div>
                    <div className="ltn__utilize-menu-search-form">
                        <form action="#">
                            <input type="text" placeholder="Search..." />
                            <button>
                                <i className="fas fa-search" />
                            </button>
                        </form>
                    </div>
                    <div className="ltn__utilize-menu">
                        <ul>
                            <li>
                                <a href="#">Home</a>
                            </li>
                            <li>
                                <a href="#">About</a>
                            </li>
                            <li>
                                <a href="#">Contact</a>
                            </li>
                        </ul>
                    </div>
                    <div className="ltn__social-media-2">
                        <ul>
                            <li>
                                <a href="#" title="Facebook">
                                    <i className="fab fa-facebook-f" />
                                </a>
                            </li>
                            <li>
                                <a href="#" title="Twitter">
                                    <i className="fab fa-twitter" />
                                </a>
                            </li>
                            <li>
                                <a href="#" title="Linkedin">
                                    <i className="fab fa-linkedin" />
                                </a>
                            </li>
                            <li>
                                <a href="#" title="Instagram">
                                    <i className="fab fa-instagram" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* Utilize Mobile Menu End */}
            <div className="ltn__utilize-overlay" />
        </>
    )
}

export default Header;